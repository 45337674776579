// Main Style

// General
@import "variables";
@import "vendor/bootstrap-includes";
@import "helpers/all";
@import "global";
@import "components/all";
@import "pages/all";

#django-background {
    color: #092e20;
    font-size: 11pt;
    background-repeat: no-repeat;
    background-size: auto 200px;
    background-position: center;
    height: 300px;
    background-image: url('../assets/images/django-logo-positive.png');
}

#django-logo-wrapper {
    color: #092e20;
    & > img {
        width: 100px;
    }
    margin-bottom: 1em;
}
