@charset "UTF-8";

/* Spinners */

.loader--spinner {
    display: inline-block;
    position: relative;
    color: official;
    height: 60px;
    width: 60px;
}

.loader--spinner div {
    animation: loader--spinner 1.2s linear infinite;
    transform-origin: 30px 30px;
}

.loader--spinner div:after {
    display: block;
    position: absolute;
    top: 3px;
    left: 27px;
    border-radius: 20%;
    content: " ";
    height: 10px;
    width: 5px;
}

.loader--spinner div:nth-child(1) {
    animation-delay: -1.1s;
    transform: rotate(0deg);
}

.loader--spinner div:nth-child(2) {
    animation-delay: -1s;
    transform: rotate(30deg);
}

.loader--spinner div:nth-child(3) {
    animation-delay: -0.9s;
    transform: rotate(60deg);
}

.loader--spinner div:nth-child(4) {
    animation-delay: -0.8s;
    transform: rotate(90deg);
}

.loader--spinner div:nth-child(5) {
    animation-delay: -0.7s;
    transform: rotate(120deg);
}

.loader--spinner div:nth-child(6) {
    animation-delay: -0.6s;
    transform: rotate(150deg);
}

.loader--spinner div:nth-child(7) {
    animation-delay: -0.5s;
    transform: rotate(180deg);
}

.loader--spinner div:nth-child(8) {
    animation-delay: -0.4s;
    transform: rotate(210deg);
}

.loader--spinner div:nth-child(9) {
    animation-delay: -0.3s;
    transform: rotate(240deg);
}

.loader--spinner div:nth-child(10) {
    animation-delay: -0.2s;
    transform: rotate(270deg);
}

.loader--spinner div:nth-child(11) {
    animation-delay: -0.1s;
    transform: rotate(300deg);
}

.loader--spinner div:nth-child(12) {
    animation-delay: 0s;
    transform: rotate(330deg);
}

@keyframes loader--spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* Ring */

.loader--ring {
    display: inline-block;
    position: relative;
    height: 64px;
    width: 64px;
}

.loader--ring div {
    display: block;
    position: absolute;
    animation: loader--ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 6px solid #fff;
    border-radius: 50%;
    box-sizing: border-box;
    margin: 6px;
    height: 51px;
    width: 51px;
}

.loader--ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.loader--ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader--ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loader--ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Roller */

.loader--roller {
    display: inline-block;
    position: relative;
    height: 64px;
    width: 64px;
}

.loader--roller div {
    animation: loader--roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
}

.loader--roller div:after {
    display: block;
    position: absolute;
    border-radius: 50%;
    content: " ";
    margin: -3px 0 0 -3px;
    height: 6px;
    width: 6px;
}

.loader--roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.loader--roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
}

.loader--roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.loader--roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
}

.loader--roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.loader--roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
}

.loader--roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.loader--roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
}

.loader--roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.loader--roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
}

.loader--roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.loader--roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
}

.loader--roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.loader--roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
}

.loader--roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.loader--roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
}

@keyframes loader--roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Spinner default */

.loader--default {
    display: inline-block;
    position: relative;
    height: 64px;
    width: 64px;
}

.loader--default div {
    position: absolute;
    animation: loader--default 1.2s linear infinite;
    border-radius: 50%;
    height: 5px;
    width: 5px;
}

.loader--default div:nth-child(1) {
    animation-delay: 0s;
    top: 29px;
    left: 53px;
}

.loader--default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 18px;
    left: 50px;
}

.loader--default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 9px;
    left: 41px;
}

.loader--default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 6px;
    left: 29px;
}

.loader--default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 9px;
    left: 18px;
}

.loader--default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 18px;
    left: 9px;
}

.loader--default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 29px;
    left: 6px;
}

.loader--default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 41px;
    left: 9px;
}

.loader--default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 50px;
    left: 18px;
}

.loader--default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 53px;
    left: 29px;
}

.loader--default div:nth-child(11) {
    animation-delay: -1s;
    top: 50px;
    left: 41px;
}

.loader--default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 41px;
    left: 50px;
}

@keyframes loader--default {
    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}


/* Spinner elipses */

.loader--ellipsis {
    display: inline-block;
    position: relative;
    height: 64px;
    width: 64px;
}

.loader--ellipsis div {
    position: absolute;
    top: 27px;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    border-radius: 50%;
    height: 11px;
    width: 11px;
}

.loader--ellipsis div:nth-child(1) {
    left: 6px;
    animation: loader--ellipsis1 0.6s infinite;
}

.loader--ellipsis div:nth-child(2) {
    left: 6px;
    animation: loader--ellipsis2 0.6s infinite;
}

.loader--ellipsis div:nth-child(3) {
    left: 26px;
    animation: loader--ellipsis2 0.6s infinite;
}

.loader--ellipsis div:nth-child(4) {
    left: 45px;
    animation: loader--ellipsis3 0.6s infinite;
}

@keyframes loader--ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes loader--ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes loader--ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(19px, 0);
    }
}


/* Spinner grid */

.loader--grid {
    display: inline-block;
    position: relative;
    height: 100px;
    width: 100px;
}

.loader--grid div {
    position: absolute;
    animation: loader--grid 1.2s linear infinite;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.loader--grid div:nth-child(1) {
    top: 0px;
    left: 0px;
    animation-delay: 0s;
}

.loader--grid div:nth-child(2) {
    top: 0px;
    left: 35px;
    animation-delay: -0.4s;
}

.loader--grid div:nth-child(3) {
    top: 0px;
    left: 70px;
    animation-delay: -0.8s;
}

.loader--grid div:nth-child(4) {
    top: 35px;
    left: 0px;
    animation-delay: -0.4s;
}

.loader--grid div:nth-child(5) {
    top: 35px;
    left: 35px;
    animation-delay: -0.8s;
}

.loader--grid div:nth-child(6) {
    top: 35px;
    left: 70px;
    animation-delay: -1.2s;
}

.loader--grid div:nth-child(7) {
    top: 70px;
    left: 0px;
    animation-delay: -0.8s;
}

.loader--grid div:nth-child(8) {
    top: 70px;
    left: 35px;
    animation-delay: -1.2s;
}

.loader--grid div:nth-child(9) {
    top: 70px;
    left: 70px;
    animation-delay: -1.6s;
}

@keyframes loader--grid {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}


/* Spinner ripple */

.loader--ripple {
    display: inline-block;
    position: relative;
    height: 64px;
    width: 64px;
}

.loader--ripple div {
    position: absolute;
    animation: loader--ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border: 4px solid;
    border-radius: 50%;
    opacity: 1;
}

.loader--ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes loader--ripple {
    0% {
        top: 28px;
        left: 28px;
        opacity: 1;
        height: 0;
        width: 0;
    }

    100% {
        top: -1px;
        left: -1px;
        opacity: 0;
        height: 58px;
        width: 58px;
    }
}


/* Spinner dual ring */

.loader--dual-ring {
    display: inline-block;
    height: 64px;
    width: 64px;
}

.loader--dual-ring:after {
    display: block;
    animation: loader--dual-ring 1.2s linear infinite;
    border: 5px solid #fff;
    border-radius: 50%;
    content: " ";
    margin: 1px;
    height: 46px;
    width: 46px;
}

@keyframes loader--dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Spinner facebook */

.loader--facebook {
    display: inline-block;
    position: relative;
    height: 64px;
    width: 64px;
}

.loader--facebook div {
    display: inline-block;
    position: absolute;
    left: 6px;
    animation: loader--facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    width: 13px;
}

.loader--facebook div:nth-child(1) {
    left: 6px;
    animation-delay: -0.24s;
}

.loader--facebook div:nth-child(2) {
    left: 26px;
    animation-delay: -0.12s;
}

.loader--facebook div:nth-child(3) {
    left: 45px;
    animation-delay: 0;
}

@keyframes loader--facebook {
    0% {
        top: 6px;
        height: 51px;
    }

    50%,
    100% {
        top: 19px;
        height: 26px;
    }
}


/**
 * Spinners colours
 * ---------------------------------------------------------
 */


/* Blue loaders in white background */

.section--white {
    color: #00539f;
    background: #fff;
}

.section--white .loader--spinner div:after,
.section--white .loader--roller div:after,
.section--white .loader--default div,
.section--white .loader--ellipsis div,
.section--white .loader--grid div,
.section--white .loader--facebook div {
    background: #f07f05;
}

.section--white .loader--ripple div {
    border-color: #00539f;
}

.section--white .loader--ring div {
    border-color: #00539f transparent transparent transparent;
}

.section--white .loader--dual-ring:after {
    border-color: #00539f transparent #00539f transparent;
}


/* White loaders in blue background */

.section--blue {
    color: #fff;
    background: #00539f;
}

.section--blue .loader--spinner div:after,
.section--blue .loader--roller div:after,
.section--blue .loader--default div,
.section--blue .loader--ellipsis div,
.section--blue .loader--grid div,
.section--blue .loader--facebook div {
    background: #fff;
}

.section--blue .loader--ripple div {
    border-color: #fff;
}

.section--blue .loader--ring div {
    border-color: #fff transparent transparent transparent;
}

.section--blue .loader--dual-ring:after {
    border-color: #fff transparent #fff transparent;
}

